import React from "react"
import Layout from "../../component/layout";
import Meta from "../../component/meta"


export default ({location}) => (
<React.Fragment>
<Meta
  title="Рома Гуйван: Мои поделки и прочий самопал"
  lang="ru"
  keywords={[]}></Meta>
<Layout language={ location.pathname.split('/')[1] }>
  <h1>Самопал </h1>
    <p> 
       Тут будет список очень важных вещей
    </p>
    <p><a href="https://master.d20yxzke9em04a.amplifyapp.com/">Purple Man Simulator</a> Мой остросюжетный стелс на юнити</p>
    <p><a href="https://www.roman-guivan.online/unity/game1/">3d волейбольчик на unity</a> - потому что я мог</p>
    <p> 
        <a href="http://extreme-wiki.roman-guivan.online"> Extreme sports games list</a> - Все помнят Tony Hawks Pro Skater? Может кто еще SSX играл. Я собираю справочник всех похожих на это игр и сопутствующих явлений. Тони Хоук на серфе, игра про фингерборд, такие дела
    </p>
    
    
    <h4>Архив очень старых запилов из 2016</h4>
    <p>Когда-то давным-давно у меня уже был сайт, и на нём были какие-то игры какие я пытался делать. Всё это - написано через пень-колоду, но все где-то начинали, мне нечего смущаться :)</p>
    <ul>
      <li><a href="/old-site/eggjump/build/index.htm">Про зайчика и прыжки</a> - это должно было быть что-то для мобилок, в брауере и похожее на дудл джамп. Контролить можно и с клавы, но в стартовый экран надо всё-таки тыкнуть. Скорость опять нечеловеческая, не знаю почему, пацаны.</li>
      <li><a href="/old-site/match-three/build/index.htm">Матч-три. С зверухами</a> Эта игра и игра про зайца - две самых "отполированных" из всего что я когда-либо делал</li>
      <li><a href="/old-site/three-snake/index.htm">Моя 3D-змейка на three.js </a></li>
      <li><a href="/old-site/pacman/index.htm">Pacman с спрайтами из покемонов для гба</a> - я пытался разобраться, как заставить AI "ловить" игрока. Писал нахождение пути к точки "фладом", самая обычная тема. За все эти годы в браузерах похоже намало изменилось, и игра моя идёт быстрей обычного. Я занизил ФПС до очень неортодоксального 45 вместо 60 в сеттингах сейчас, чтоб это можно было играть</li>
      <li><a href="/old-site/picross/index.htm">Вот эти знаешь, ЯПОНСКИЕ КРОССВОРДЫ</a> но почему-то изометрические и про песок. Идея на тройбан была, знаю</li>
    </ul>
</Layout>
</React.Fragment>)